import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EfsService } from "@app/efs/efs.service";
import { MaskService } from "@app/shared/services/mask/mask.service";
import { SpinnerService } from "@app/shared/services/spinner/spinner.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-efs-sync-modal",
  templateUrl: "./efs-sync-modal.component.html",
  styleUrl: "./efs-sync-modal.component.css",
})
export class EfsSyncModalComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() syncCompleted = new EventEmitter<any>();

  date: Date | string = new Date().toISOString().split("T")[0];
  dateInputMask: any;

  constructor(
    private maskService: MaskService,
    private efsService: EfsService,
    private toastr: ToastrService,
    private spinner: SpinnerService
  ) {}

  ngOnInit() {
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  closeModal() {
    this.date = new Date();
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  sync() {
    this.spinner.show();
    this.efsService.sync(new Date(this.date)).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.toastr.success("EFS data synced successfully");
        this.syncCompleted.emit(res);
        this.closeModal();
      },
      error: (error) => {
        this.spinner.hide();
        this.toastr.error("Error importing EFS data");
        console.error(error);
      },
    });
  }
}
