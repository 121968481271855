<div class="tab-pane active">
<div class="d-flex btnRig">
  @if (hasPermission('LocationsChange')) {
  <button (click)="openLocationCreateModal()" class="btn btn-primary">Add New Location</button>
  }
</div>

<div
  class="modal"
  id="location-create-modal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="location-create-modal-label"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="location-create-modal-label" class="modal-title">Add New Location</h5>
        <button type="button" class="close" aria-label="Close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <div class="form-group">
            <label for="name">Name</label>
            <input
              id="name"
              name="name"
              type="text"
              [(ngModel)]="locationForm.name"
              (change)="genLocationId()"
              placeholder="Insert new name"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="countryAbbreviation">Country</label>
            <select
              id="countryAbbreviation"
              name="countryAbbreviation"
              [(ngModel)]="locationForm.countryAbbreviation"
              (ngModelChange)="setLocationCountry(locationForm)"
              (change)="genLocationId()"
              class="form-control inputForm"
              required
            >
              @for (country of countries; track country.abbreviation) {
              <option [ngValue]="country.abbreviation">
                {{ country.name }}
              </option>
              }
            </select>
          </div>
          <div class="form-group">
            <label for="address">Address</label>
            <input
              id="address"
              name="address"
              type="text"
              [(ngModel)]="locationForm.address"
              placeholder="Insert new address"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="city">City</label>
            <input
              id="city"
              name="city"
              type="text"
              [(ngModel)]="locationForm.city"
              (change)="genLocationId()"
              placeholder="Insert origin city"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="stateAbbreviation">State</label>
            <select
              id="stateAbbreviation"
              name="stateAbbreviation"
              [(ngModel)]="locationForm.stateAbbreviation"
              (ngModelChange)="setLocationState(locationForm)"
              (change)="genLocationId()"
              class="form-control inputForm"
              required
            >
              <option [ngValue]="''" disabled>Select one</option>
              @for (state of getStatesByCountry(locationForm.countryAbbreviation); track
              state.abbreviation) {
              <option [ngValue]="state.abbreviation">
                {{ state.abbreviation }} - {{ state.fullName }}
              </option>
              }
            </select>
          </div>
          <div class="form-group">
            <label for="zipcode">Zip</label>
            <input
              id="zipcode"
              name="zipcode"
              type="text"
              [(ngModel)]="locationForm.zipcode"
              placeholder="Insert ZIP code"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="form-group">
            <label for="locationId">Location Id</label>
            <input
              id="locationId"
              name="locationId"
              type="text"
              [(ngModel)]="locationForm.locId"
              placeholder="ID generated"
              class="form-control inputForm"
              required
            />
          </div>
          <div class="row">
            <div class="form-group">
              <label class="col-md-6" for="isShipper">Shipper</label>
              <div class="col-md-6">
                <p-checkbox
                  id="isShipper"
                  name="isShipper"
                  binary="true"
                  [(ngModel)]="locationForm.isShipper"
                ></p-checkbox>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label class="col-md-6" for="isConsignee">Consignee</label>
              <div class="col-md-6">
                <p-checkbox
                  id="isConsignee"
                  name="isConsignee"
                  binary="true"
                  [(ngModel)]="locationForm.isConsignee"
                ></p-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" data-dismiss="modal" class="modalElem btn btn-secondary">
          Close
        </button>
        <button type="button" (click)="createLocation()" class="btn btn-primary">
          Save New Location
        </button>
      </div>
    </div>
  </div>
</div>

<p-dialog
  header="Select Near Location"
  [(visible)]="exactLocationsData.length"
  [style]="{ 'max-width': '1600px' }"
>
  <h4>Please select one of the available options.</h4>
  <app-pc-miller-near-locations
    [locations]="exactLocationsData"
    (locationSelected)="finishLocationCreateUpdate($event)"
  ></app-pc-miller-near-locations>
</p-dialog>
</div>