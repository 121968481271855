import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { Subject, Subscription, of, map } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { authenticationService } from "@app/login/auth.service";
import { LocationsService } from "../shared/locations.service";
import { StatesServices } from "../../shared/services/states/states.service";
import { ToastrService } from "ngx-toastr";
import { PcMilerService } from "../../pcMiler/shared/pcMiler.service";
import { Permissions } from "@models/permissions.enum";

@Component({
  selector: "app-location-modal",
  templateUrl: "./location-modal.component.html",
  styleUrls: ["./location-modal.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationModalComponent implements OnInit, OnDestroy {

  columns = [
    { field: "countryAbbreviation", header: "Country", width: "110px" },
    { field: "name", header: "Name", width: "350px" },
    { field: "address", header: "Address", width: "350px" },
    { field: "city", header: "City", width: "220px" },
    { field: "stateAbbreviation", header: "State", width: "110px" },
    { field: "zipcode", header: "Zip", width: "90px" },
    { field: "latitude", header: "Latitude", width: "120px" },
    { field: "longitude", header: "Longitude", width: "120px" },
    { field: "shipper", header: "Shipper", width: "90px" },
    { field: "consignee", header: "Consignee", width: "110px" },
    { field: "billTo", header: "Bill To", width: "80px" },
    { field: "confidenceLevel", header: "Confidence", width: "200px" },
    { field: "numMatches", header: "Matches", width: "100px" },
  ];

  rows = 100;
  locations = [];
  locationSnapshots = {};
  canEditLocations = false;
  totalRecords = 0;
  isLoading = true;

  locationForm: any = {};
  locationReference = null;
  exactLocationsData: any = [];

  filtersSubject = new Subject();
  filtersSubscription: Subscription;
  filters = {
    locId: "",
    countryAbbreviation: "",
    name: "",
    address: "",
    city: "",
    stateAbbreviation: "",
    zipcode: "",
    confidenceLevel: "",
    numMatches: "",
  };

  countries = [
    { name: "CANADA", abbreviation: "CA" },
    { name: "MEXICO", abbreviation: "MX" },
    { name: "US", abbreviation: "US" },
  ];

  constructor(
    private _AuthService: authenticationService,
    private _LocationService: LocationsService,
    private _StatesService: StatesServices,
    private _ToastrService: ToastrService,
    private _PCMilerService: PcMilerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.filtersSubscription = this.filtersSubject.pipe(debounceTime(500)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  hasPermission(name: string) {
    return this._AuthService.hasPermission(Permissions[name]);
  }

  isFilterable(field: string) {
    return Object.keys(this.filters).includes(field);
  }

  isEditingLocation(location: any) {
    return !!this.locationSnapshots[location.id];
  }

  createLocation() {
    this.validateNewLocation().subscribe((result: any) => {
      if (result) {
        this.openSelectExactAddressDialog(this.locationForm);
        $("#location-create-modal").modal("hide");
        this.cdr.markForCheck();
      }
    });
  }

  createLocationImmediate(location: any) {
    this._LocationService.createLocation(location).subscribe({
      next: (result: any) => {
        this.locations = [result, ...this.locations];
        this._ToastrService.success("Location created.", "Success", {
          closeButton: true,
          enableHtml: true,
        });

        this.locationReference = null;
        this.exactLocationsData = [];
        this.cdr.markForCheck();
      },
      error: (error) => {
        this._ToastrService.error(error, "Error");
        $("#location-create-modal").modal("show");
        this.exactLocationsData = [];
        this.cdr.markForCheck();
      },
    });
  }

  openLocationCreateModal() {
    this.locationForm = {};
    this.locationForm.countryAbbreviation = "US";
    this.locationForm.country = "US";
    this.locationForm.stateAbbreviation = "";
    this.locationForm.state = "";
    $("#location-create-modal").modal("show");
    $(".modal-backdrop").remove();
  }

  async openSelectExactAddressDialog(location: any) {
    this.locationReference = location;

    try {
      /*
      The dialog will open automatically when `exactLocationsData` is populated then
      later call `finishLocationCreateUpdate` below to finish the update process.
      */
      this.exactLocationsData = await this._PCMilerService.geoCodeLocation(location);
      this.cdr.markForCheck();
    } catch (error) {
      if (error.responseText === "Input postal code doesn't match input state") {
        this._ToastrService.error("ZIP and State don't match");
      } else {
        try {
          const errors = JSON.parse(error.responseText);
          setTimeout(() => $("#location-create-modal").modal("show"), 500);

          if (!errors.length) {
            this._ToastrService.error("An error occurred when trying to geocode the location.");
          } else if (errors[0]["Code"] === 25) {
            this._ToastrService.error("You must provide a City or ZIP.");
          } else {
            const message = errors[0]["Description"];
            this._ToastrService.error(message, "Error");
          }
        } catch (err) {
          this._ToastrService.error(error.responseText, "Error");
        }
      }
    }
  }

  finishLocationCreateUpdate(exactLocationData: any) {
    this.locationReference.address = exactLocationData.Address.StreetAddress;
    this.locationReference.city = exactLocationData.Address.City;
    this.locationReference.state = exactLocationData.Address.State;
    this.locationReference.stateAbbreviation = exactLocationData.Address.StateAbbreviation;
    this.locationReference.zipcode = exactLocationData.Address.Zip;
    this.locationReference.country = exactLocationData.Address.Country;
    this.locationReference.countryAbbreviation = exactLocationData.Address.CountryAbbreviation;
    this.locationReference.latitude = exactLocationData.Coords.Lat;
    this.locationReference.longitude = exactLocationData.Coords.Lon;
    this.locationReference.confidenceLevel = exactLocationData.ConfidenceLevel;
    this.locationReference.numMatches = exactLocationData.numMatches;
    this.locationReference.isUserChosen = true;

    const isCreating = this.locationReference === this.locationForm;
    if (isCreating) this.createLocationImmediate(this.locationReference);
  }

  getStatesByCountry(countryAbbreviation) {
    return this._StatesService.getTwoLettersStates(countryAbbreviation);
  }

  setLocationCountry(location: any) {
    const predicate = (c: any) => c.abbreviation === location.countryAbbreviation;
    location.country = this.countries.find(predicate).name;
    location.stateAbbreviation = "";
    location.state = "";
  }

  setLocationState(location: any) {
    const states = this.getStatesByCountry(location.countryAbbreviation);
    const predicate = (s: any) => s.abbreviation === location.stateAbbreviation;
    location.state = states.find(predicate).fullName;
  }

  genLocationId() {
    if (!this.locationForm.name) return;
    if (!this.locationForm.city) return;
    if (!this.locationForm.state) return;

    const nameSlice = this.locationForm.name.slice(0, 4);
    const citySlice = this.locationForm.city.slice(0, 2);
    const stateSlice = this.locationForm.state.slice(0, 2);
    this.locationForm.locId = nameSlice + citySlice + stateSlice;
  }

  validateNewLocation() {
    var warnings: string[] = [];
    var orderedWarnings: string[] = [];

    if (!this.locationForm.name) {
      warnings.push("Location Name is required");
    }
    if (!this.locationForm.city && !this.locationForm.zipcode) {
      warnings.push("You must provide a City or ZIP");
    }
    if (!this.locationForm.locId) {
      warnings.push("Location ID is required");

      orderedWarnings = warnings.reverse();

      if (warnings.length > 0) {
        for (const warning of orderedWarnings) {
          this._ToastrService.error(warning);
        }
        return of(false);
      }
      return of(true);
    } else {
      return this._LocationService.checkLocationIdExists(this.locationForm.locId).pipe(
        map((result: any) => {
          if (result) {
            warnings.push("Location ID already exists");
          }

          orderedWarnings = warnings.reverse();

          if (warnings.length > 0) {
            for (const warning of orderedWarnings) {
              this._ToastrService.error(warning);
            }
            return false;
          }
          return true;
        })
      );
    }
  }
}
