import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-trip-margin",
  templateUrl: "./margin.component.html",
  styleUrls: ["./margin.component.css"],
})
export class MarginComponent implements OnInit {
  @Input("margin") margin: any;
  @Input("marginPercent") marginPercent: any;

  constructor(
  ) {}

  ngOnInit() {
  }

  initComponent() {
  }
}
