<div class="container">
  <div class="col-md-12">
    <legend>EFS Import</legend>

    <div style="display: flex; align-items: center">
      <a class="btn btnUpload" (click)="sync()">
        <span>Sync with EFS</span>
        <p-progressSpinner
          *ngIf="syncing"
          [style]="{ width: '16px', height: '16px', marginLeft: '10px' }"
        ></p-progressSpinner>
      </a>
      <div style="margin-left: 10px">Last synced {{ lastSyncDate | date : "medium" }}</div>
    </div>
    <div style="margin-top: 10px; margin-bottom: 20px">
      <a class="btn btnUpload" (click)="import()">
        <span>Import Fuel & Express</span>
      </a>
    </div>

    <div *ngIf="importErrors.length > 0">
      <legend style="color: red">Errors</legend>

      <div class="table-container">
        <p-table
          [columns]="errorCols"
          [value]="importErrors"
          [scrollable]="true"
          [virtualScroll]="true"
          [virtualScrollItemSize]="42"
          [autoLayout]="true"
          scrollHeight="flex"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record let-y="rowIndex">
            <tr>
              <td>{{ record.message }}</td>
              <td>{{ record.driverId }}</td>
              <td>{{ record.driverName }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div *ngIf="importWarnings.length > 0">
      <legend style="color: orange">Warnings</legend>

      <div class="table-container">
        <p-table
          [columns]="warningCols"
          [value]="importWarnings"
          [scrollable]="true"
          [virtualScroll]="true"
          [virtualScrollItemSize]="42"
          [autoLayout]="true"
          scrollHeight="flex"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" [pSortableColumn]="col.field">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-record let-y="rowIndex">
            <tr>
              <td>{{ record.message }}</td>
              <td>{{ record.transactionId }}</td>
              <td>{{ record.driverId }}</td>
              <td>{{ record.driverName }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

    <div *ngIf="fuelRecords.length > 0 || moneyCodes.length > 0">
      <div style="height: 950px">
        <app-editable-fuel-records-table
          [(records)]="fuelRecords"
        ></app-editable-fuel-records-table>
      </div>

      <div style="margin-top: 20px">
        <app-editable-money-codes-table
          [(moneyCodes)]="moneyCodes"
          [isNewRecords]="true"
        ></app-editable-money-codes-table>
      </div>

      <div class="text-right" style="margin-top: 10px; margin-bottom: 10px">
        <button class="btn btn-primary" (click)="save()">Import to Batch</button>
      </div>
    </div>
  </div>
</div>

<app-efs-import-modal
  [(visible)]="importOpen"
  (importCompleted)="onImportCompleted($event)"
></app-efs-import-modal>

<app-efs-sync-modal
  [(visible)]="syncOpen"
  (syncCompleted)="onSyncCompleted($event)"
></app-efs-sync-modal>
