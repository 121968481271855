<div>
  <div class="col-md-12 no-padding">
    <legend>Admin</legend>
    <div class="row">
      <div class="col-md-2">API Key</div>
      <div class="col-md-3">
        <input type="text" class="form-control input-md inp" readonly [(ngModel)]="apikey" />
      </div>
    </div>
  </div>
</div>
