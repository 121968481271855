import { throwError as observableThrowError, Observable } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../../../shared/services/http/http-client.service";

@Injectable({
  providedIn: "root",
})
export class ApikeyService {
  constructor(private _http: HttpClient) {}

  getApiKey() {
    return this._http.get("api/apikey/getKey").pipe(
      map((res: any) => {
        return res.key;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
