import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { EfsService } from "@app/efs/efs.service";
import { MaskService } from "@app/shared/services/mask/mask.service";
import { SpinnerService } from "@app/shared/services/spinner/spinner.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-efs-import-modal",
  templateUrl: "./efs-import-modal.component.html",
  styleUrl: "./efs-import-modal.component.css",
})
export class EfsImportModalComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();
  @Output() importCompleted = new EventEmitter<any>();

  date: string = new Date().toISOString().split("T")[0];
  dateInputMask: any;

  constructor(
    private maskService: MaskService,
    private efsService: EfsService,
    private toastr: ToastrService,
    private spinner: SpinnerService
  ) {}

  ngOnInit() {
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  closeModal() {
    this.date = new Date().toISOString().split("T")[0];
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  import() {
    this.spinner.show();
    // date string is in the format yyyy-mm-dd and will be parsed as utc
    // but I need it to be parsed in the form mm/dd/yyyy so it will be parsed as local time
    const splitDate = this.date.split("-");
    const localDate = `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
    this.efsService.syncWithEfs(new Date(localDate)).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.toastr.success("EFS data synced successfully");
        this.importCompleted.emit(res);
        this.closeModal();
      },
      error: (error) => {
        this.spinner.hide();
        this.toastr.error("Error importing EFS data");
        console.error(error);
      },
    });
  }
}
