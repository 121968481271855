<div class="row">
    <div class="col-md-12">
      <div class="d-flex" style="align-items: center">
        <h3 class="TripHeader">
          Margin
        </h3>
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-md-12">
      <!--Trip charges table contain the inputs for each charge-->
      <table class="table">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
            <th>Percentage</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="subTotal">Gross Margin</td>
            <td class="subTotal">{{ margin | currency : "$" : "symbol" : "2.2-4" }}</td>
            <td class="subTotal">{{ marginPercent | number : '1.2-2' }}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <hr />
  