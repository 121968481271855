import { Component, OnInit } from "@angular/core";
import { ApikeyService } from "../config/shared/apikey.service";

@Component({
  selector: "app-apikey-admin",
  templateUrl: "./apikey-admin.component.html",
  styleUrl: "./apikey-admin.component.css",
})
export class ApikeyAdminComponent implements OnInit {
  apikey: string;

  constructor(private apikeyService: ApikeyService) {}

  ngOnInit() {
    this.apikeyService.getApiKey().subscribe(
      (res: any) => {
        this.apikey = res;
      },
      (error) => {
        console.log("error", error);
      }
    );
  }
}
