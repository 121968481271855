<p-dialog
  header="Sync with EFS"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '500px', minWidth: '300px' }"
  [closeOnEscape]="false"
>
  <label for="date">Sync Date</label> <br />
  <input
    type="date"
    autocomplete="off"
    [(ngModel)]="date"
    [attr.aria-label]="date"
    class="form-control"
    id="date"
  />
  <p-footer>
    <button type="button" class="btn btn-secondary mr-3" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="sync()">Sync</button>
  </p-footer>
</p-dialog>
