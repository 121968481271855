import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { FuelRecordGridModel } from "@app/shared/models/fuel/FuelRecordGridModel.model";
import { MoneyCodeGridModel } from "@app/shared/models/fuel/MoneyCodeGridModel";
import { SelectItem } from "primeng/api";

@Component({
  selector: "app-settlement-fuel-tab",
  templateUrl: "./settlement-fuel-tab.component.html",
  styleUrls: ["../loads.component.css"],
})
export class SettlementFuelTabComponent implements OnInit, OnChanges {
  @Input() fuelRecords!: any[];
  @Output() fuelRecordChanged = new EventEmitter<any>();
  @Input() moneyCodes!: any[];
  @Output() moneyCodeChanged = new EventEmitter<any>();
  @Input() batchSelected!: any;

  fuelRecordTotals: any = {};
  moneyCodeTotals: any = {};
  chargeTypes: SelectItem;

  cols = [
    { field: "transactionId", header: "Transaction", type: "code" },
    { field: "transactionDate", header: "Date", type: "date" },
    { field: "locationName", header: "Location", type: "string" },
    { field: "locationCity", header: "City", type: "string" },
    { field: "locationState", header: "State", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
    { field: "quantity", header: "Quantity", type: "number" },
    { field: "ppu", header: "PPG", type: "currency" },
    { field: "productCode", header: "Product", type: "string" },
    { field: "settlementColumn", header: "Type", type: "string" },
    { field: "fuel", header: "Fuel", type: "currency" },
    { field: "other", header: "Other", type: "currency" },
    { field: "cash", header: "Cash", type: "currency" },
    { field: "fee", header: "Fee", type: "currency" },
    { field: "discount", header: "Discount", type: "currency" },
  ];

  moneyCodeCols = [
    { field: "transactionId", header: "Transaction", type: "code" },
    { field: "activeDate", header: "Date", type: "date" },
    { field: "issuedTo", header: "Location", type: "string" },
    { field: "", header: "City", type: "string" },
    { field: "", header: "State", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
    { field: "", header: "Quantity", type: "number" },
    { field: "", header: "PPG", type: "currency" },
    { field: "", header: "Product", type: "expressCode" },
    { field: "settlementColumn", header: "Type", type: "string" },
    { field: "fuel", header: "Fuel", type: "currency" },
    { field: "other", header: "Other", type: "currency" },
    { field: "cash", header: "Cash", type: "currency" },
    { field: "feeAmount", header: "Fee", type: "currency" },
    { field: "discount", header: "Discount", type: "currency" },
  ];

  constructor(private _ActivatedRoute: ActivatedRoute) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateTotals();
  }

  ngOnInit(): void {
    const resolvers = this._ActivatedRoute.snapshot.data;
    this.chargeTypes = resolvers.chargeTypes;
  }

  calculateTotals() {
    const totalColumns = ["fuel", "other", "cash", "discount", "fee", "total"];

    for (const row of this.fuelRecords) {
      row.total = row.fuel + row.other + row.cash + row.discount + row.fee;
    }

    for (const column of totalColumns) {
      this.fuelRecordTotals[column] = this.fuelRecords.reduce((a, b) => a + b[column], 0);
    }

    const moneytotalColumns = ["fuel", "other", "cash", "feeAmount", "discount", "total"];

    for (const row of this.moneyCodes) {
      row.total = row.fuel + row.other + row.cash + row.discount + row.feeAmount;
    }

    for (const column of moneytotalColumns) {
      this.moneyCodeTotals[column] = this.moneyCodes.reduce((a, b) => a + b[column], 0);
    }
  }

  onRecordChanged(newValue: string | number, fuelRecord: any, field: string) {
    newValue = newValue ?? 0;
    const oldValue = fuelRecord[field];
    const key = `${field}OriginalValue`;
    if (fuelRecord[key] === undefined) {
      fuelRecord[key] = oldValue;
    }
    fuelRecord[`${field}Changed`] = fuelRecord[key] !== newValue;
    fuelRecord.softUpdate = true;
    fuelRecord[field] = newValue;

    this.calculateTotals();

    this.fuelRecordChanged.emit(this.fuelRecords);
  }

  onMoneyCodeChanged(newValue: string | number, moneyCode: any, field: string) {
    newValue = newValue ?? 0;
    const oldValue = moneyCode[field];
    const key = `${field}OriginalValue`;
    if (moneyCode[key] === undefined) {
      moneyCode[key] = oldValue;
    }
    moneyCode[`${field}Changed`] = moneyCode[key] !== newValue;
    moneyCode.softUpdate = true;
    moneyCode[field] = newValue;

    this.calculateTotals();

    this.moneyCodeChanged.emit(this.moneyCodes);
  }

  openFuelExpensesGrid(record: any, tab: string) {
    const url = `/efsBatchHistory?batch=${this.batchSelected.batchId}&tab=${tab}`;
    window.open(url, "_blank");
  }

  private isAmountEqual(amount1: number, amount2: number, precision: number = 2): boolean {
    if (amount1 === undefined || amount2 === undefined) return false;
    const epsilon = 0.000001; // Small tolerance for floating point comparison
    const diff = Math.abs(Number(amount1.toFixed(precision)) - Number(amount2.toFixed(precision)));
    return diff < epsilon;
  }

  getEditedColor(record: FuelRecordGridModel, field: string): string {
    if (!record.syncedFuelRecord) return "";

    const quantityFields = ["fuel", "other", "cash"];
    const syncedType = record.settlementColumn?.toLowerCase();
    const syncedRecord = record.syncedFuelRecord;

    if (field == "chainName") return "";
    else if (quantityFields.includes(field.toLowerCase())) {
      // get the intended amount, which is the transaction amount if the settlement column is the same as the field
      if (field === syncedType)
        return !this.isAmountEqual(
          record[field],
          syncedRecord.transactionAmount + syncedRecord.lineItemDiscount
        )
          ? "red"
          : "";
      else return record[field] !== 0 ? "red" : "";
    } else if (field == "fee") {
      if (syncedType == "fee") {
        const originalValue =
          syncedRecord.transactionAmount +
          syncedRecord.lineItemDiscount +
          syncedRecord.transactionFee;
        return this.isAmountEqual(record[field], originalValue) ? "red" : "";
      } else return record[field] !== syncedRecord.transactionFee ? "red" : "";
    } else if (field == "ppu") return record[field] !== syncedRecord.ppu ? "red" : "";
    else if (field == "discount")
      return record[field] !== -syncedRecord.lineItemDiscount ? "red" : "";
    else if (!syncedRecord) return "";
    else if (syncedRecord[field] === undefined) return "";
    return record[field] !== syncedRecord[field] ? "red" : "";
  }

  getEditedMoneyCodeColor(record: MoneyCodeGridModel, field: string): string {
    if (!record.syncedMoneyCode) return "";

    const recordMapping = {
      activeDate: "activeDate",
      amount: "amount",
      amountUsed: "amountUsed",
      transferCode: "code",
      contractId: "contractId",
      feeAmount: "feeAmount",
      firstUseDate: "firstUseDate",
      transactionId: "transactionId",
      issuedTo: "issuedTo",
      notes: "notes",
      payee: "payee",
      issuedBy: "who",
      unit: "unit",
      name: "name",
      poNumber: "ponb",
      cash: "amount",
      fee: "fee",
    };

    const zeroColumns = ["fuel", "other", "discount"];
    if (zeroColumns.includes(field)) return record[field] != 0 ? "red" : "";

    const syncedValue = record.syncedMoneyCode[recordMapping[field]];

    return record[field] != syncedValue ? "red" : "";
  }
}
